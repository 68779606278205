import { ChartCounts, FirmwareVersions, FwChartData, OldFirmwareVersions } from '#types/stats'

export const getFwChartData = (
  filteredFwVersions: FirmwareVersions,
  olderVersions: OldFirmwareVersions,
  totalCounts: ChartCounts,
  fwGenerations: { [key: string]: string },
) => {
  const charts: {
    [key: string]: FwChartData
  } = {}

  Object.keys(fwGenerations).forEach((generation) => {
    charts[generation] = {
      data: filteredFwVersions[generation],
      olderVersions: olderVersions[generation],
      xAxes: 'firmwareVersion',
      title: `All (${totalCounts[generation]})`,
      count: totalCounts[generation] as number,
      series: [
        { name: `android (${totalCounts.androidCount[generation]})`, field: 'androidCount', stacked: true },
        { name: `ios (${totalCounts.iosCount[generation]})`, field: 'iosCount', stacked: true },
      ],
      colors: ['#dc67ab', '#a0dc67'],
    }
  })

  return charts
}

<template>
  <div>
    <div v-if="chartData.olderVersions && chartData.olderVersions.length" class="text-right mt-n10 mr-12 mb-n4">
      <v-btn
        size="small"
        color="primary"
        variant="flat"
        class="mt-n4 mb-10"
        :text="showAll ? 'Hide older' : 'Show older'"
        @click="showAll = !showAll"
      />
    </div>

    <div ref="chartRef" class="view" style="height: 120px" />
  </div>
</template>

<script lang="ts">
  import { isEqual } from 'lodash-es'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Prop, Ref, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import * as am4core from '@amcharts/amcharts4/core'

  import * as am4charts from '@amcharts/amcharts4/charts'

  import am4themes_animated from '@amcharts/amcharts4/themes/animated'
  import am4themes_dark from '@amcharts/amcharts4/themes/dark'

  @Component
  class VersionChart extends Vue {
    @Prop() public chartData!: any

    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    @Ref('menuRef') public readonly menuRef!: HTMLElement
    @Ref('chartRef') public readonly chartRef!: HTMLElement

    public showAll = false

    @Watch('showAll')
    protected showAllChanges() {
      this.updateData()
    }

    @Watch('chartData')
    protected chartDataChanged(val: any, oldVal: any) {
      if (val && !isEqual(val, oldVal)) {
        this.updateData()
      }
    }

    public mounted() {
      this.updateData()
    }

    public updateData() {
      am4core.options.autoDispose = true

      am4core.useTheme(am4themes_animated)

      if (this.theme?.global?.current?.value?.dark) {
        am4core.useTheme(am4themes_dark)
      }

      let chart: any = am4core.create(this.chartRef, am4charts.XYChart)

      const xAxes = this.chartData.xAxes

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = xAxes
      categoryAxis.renderer.minGridDistance = 20
      categoryAxis.renderer.labels.template.rotation = 270
      categoryAxis.renderer.labels.template.horizontalCenter = 'right'
      categoryAxis.renderer.labels.template.verticalCenter = 'middle'

      // Configure axis label
      let label = categoryAxis.renderer.labels.template
      label.wrap = false
      label.maxWidth = 90

      chart.yAxes.push(new am4charts.ValueAxis())

      chart.colors.list = this.chartData.colors.map((color: string) => am4core.color(color))

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'top'

      chart.cursor = new am4charts.XYCursor()

      chart.responsive.enabled = true

      function createSeries(field: string, name: string, stacked: boolean) {
        let series = chart.series.push(new am4charts.ColumnSeries())

        series.dataFields.categoryX = xAxes
        series.dataFields.valueY = field

        series.name = name

        series.columns.template.focusable = true
        series.columns.template.hoverOnFocus = true

        if (stacked) {
          series.stacked = true
        }

        if (xAxes === 'clientVersion') {
          series.tooltipText = '{categoryX}: {valueY}'
          series.columns.template.tooltipText = 'xx {valueY}'
        }

        return series
      }

      this.chartData.series.forEach((seriesData: any, index: number) => {
        const series: any = createSeries(seriesData.field, seriesData.name, seriesData.stacked)

        if (index === this.chartData.series.length - 1 && xAxes === 'firmwareVersion') {
          series.tooltipText = `total: {fwVersionCount}\n ios: {iosCount} \n android: {androidCount}`
          series.tooltip.getFillFromObject = false
          series.tooltip.background.fill = am4core.color('#000')
        }
      })

      chart.events.on('ready', function () {
        hideIndicator()
      })

      let indicator: any

      function showIndicator() {
        if (!indicator) {
          indicator = chart.tooltipContainer.createChild(am4core.Container)
          indicator.background.fill = am4core.color('#fff')
          indicator.background.fillOpacity = 0.8
          indicator.width = am4core.percent(100)
          indicator.height = am4core.percent(100)

          const indicatorLabel = indicator.createChild(am4core.Label)
          indicatorLabel.text = 'Loading chart...'
          indicatorLabel.align = 'center'
          indicatorLabel.valign = 'middle'
          indicatorLabel.dy = 20
        }

        indicator.hide(0)
        indicator.show()
      }

      function hideIndicator() {
        indicator.hide()
      }

      showIndicator()

      chart.data = this.showAll ? this.chartData.olderVersions : this.chartData.data
    }
  }

  export default toNative(VersionChart)
</script>

<style lang="scss">
  .view {
    width: 100%;
    min-height: 400px;
  }
</style>

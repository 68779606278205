import { identity, pickBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { Environment } from '@jouzen/outo-toolkit-vuetify'

import { SearchInput, WarrantyReasonStats } from '#types'

const getRequestParams = (data: SearchInput) => {
  const params: any = {
    label: data?.labelListId ?? '',
    appFlavor: data.appFlavor ?? '',
    filterIds: data?.sampleListId ?? '',
    onlyActive: data?.onlyActive ?? false,
    modifiedStartDate: data?.dataStartDate ?? '',
    modifiedEndDate: data?.dataEndDate ?? '',
    registeredStartDate: data?.userStartDate ?? '',
    registeredEndDate: data?.userEndDate ?? '',
  }

  return pickBy(params, identity)
}

@Store
export class StatsStore extends Pinia {
  public loading = false

  public error: string | null = null

  public appFlavors: string[] | null = null

  public appVersions: { [key: string]: any } | null = null
  public firmwareVersions: { [key: string]: any } | null = null

  public warranty: WarrantyReasonStats | null = null

  public async listAppFlavors() {
    this.error = null
    this.loading = true

    const response = await this.$axios.get(`/api/v1/statistics/app-flavors`)

    if (response?.status !== 200) {
      this.error = response?.data?.message ?? 'An error occured while fetching data'
      this.appFlavors = null
    } else {
      this.appFlavors = response.data.appFlavors
    }

    this.loading = false

    return response
  }

  @Environment()
  public async listAppVersions(env: string, data: SearchInput) {
    this.error = null
    this.loading = true

    const response = await this.$axios.get(`/api/v1/statistics/app-versions`, {
      apiEnv: env,
      params: getRequestParams(data),
    })

    if (response?.status !== 200) {
      this.error = response?.data?.message ?? 'An error occured while fetching data'
      this.appVersions = null
    } else {
      this.appVersions = response.data.appVersions
    }

    this.loading = false

    return response
  }

  @Environment()
  public async listFirmwareVersions(env: string, data: SearchInput) {
    this.error = null
    this.loading = true

    const response = await this.$axios.get(`/api/v1/statistics/firmware-versions`, {
      apiEnv: env,
      params: getRequestParams(data),
    })

    if (response?.status !== 200) {
      this.error = response?.data?.message ?? 'An error occured while fetching data'
      this.firmwareVersions = null
    } else {
      this.firmwareVersions = response.data.firmwareVersions
    }

    this.loading = false

    return response
  }

  public async listWarrantyReasons(data: SearchInput) {
    this.error = null
    this.loading = true

    this.warranty = {} as any

    const filterIdsParams = data?.sampleListId ? `filterIds=${data?.sampleListId}` : ''

    const response = await this.$axios.get(`/api/v1/rings/warranty?${filterIdsParams}`)

    if (response?.status !== 200) {
      this.error = response?.data?.message ?? 'An error occurred while fetching data'
    } else {
      this.warranty = response?.data ?? {}
    }

    this.loading = false

    return response
  }
}

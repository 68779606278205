<template>
  <v-app-bar>
    <v-tabs style="max-width: 500px" :model-value="tab">
      <v-tab to="configs">Configs</v-tab>
      <v-tab to="protocols">Protocols</v-tab>
      <v-tab to="studies">Studies</v-tab>
    </v-tabs>

    <v-spacer />

    <v-text-field
      v-model="routeParams.searchText"
      clearable
      class="mr-2"
      style="max-width: 500px"
      placeholder="Type to filter..."
      @click:clear="routeParams.searchText = ''"
    >
      <template #prepend>
        <v-icon :color="routeParams.searchText ? 'primary' : ''">mdi-magnify</v-icon>
      </template>
    </v-text-field>

    <v-menu location="bottom end" offset="16" min-width="200" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-filter-variant-plus"
          :color="props.value ? 'primary' : ''"
          :disabled="tab === 'protocols'"
        />
      </template>

      <v-list>
        <template v-if="tab === 'configs'">
          <v-list-subheader>Select additional filters</v-list-subheader>

          <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
            <template #activator="{ props }">
              <v-list-item v-bind="props" title="Hardware types" prepend-icon="mdi-menu-left" />
            </template>
            <v-list>
              <v-list-item
                v-for="item in hardwareTypes"
                :key="item.value"
                :title="item.title"
                :disabled="routeParams.extraFilters.includes(item.value)"
                @click="routeParams.extraFilters = [...routeParams.extraFilters, item.value]"
              />
            </v-list>
          </v-menu>

          <v-menu open-on-hover z-index="1" max-height="600" location="start" :close-on-content-click="false">
            <template #activator="{ props }">
              <v-list-item v-bind="props" title="Configuration state" prepend-icon="mdi-menu-left" />
            </template>

            <v-list>
              <v-list-item>
                <v-switch
                  style="width: 230px"
                  label="Show archived configs"
                  :model-value="routeParams.includeArchived === 'true'"
                  @update:model-value="routeParams.includeArchived = $event ? 'true' : 'false'"
                />
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider />

          <v-list-item
            title="Clear all filters"
            prepend-icon="mdi-close-circle-outline"
            :disabled="!routeParams.extraFilters.length && routeParams.includeArchived !== 'true'"
            @click="((routeParams.extraFilters = []), (routeParams.includeArchived = 'false'))"
          />
        </template>

        <template v-if="tab === 'studies'">
          <v-list-subheader>App flavors</v-list-subheader>

          <v-list-item
            v-for="item in appFlavors"
            :key="item.value"
            :title="item.title"
            :disabled="routeParams.extraFilters.includes(item.value)"
            @click="routeParams.extraFilters = [...routeParams.extraFilters, item.value]"
          />
        </template>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-window v-model="tab">
    <v-window-item value="configs">
      <ConfigListing
        v-model:filters="routeParams.extraFilters"
        :search="routeParams.searchText"
        :include-archived="routeParams.includeArchived === 'true'"
      />
    </v-window-item>

    <v-window-item value="protocols">
      <ProtoListing v-model:search="routeParams.searchText" />
    </v-window-item>

    <v-window-item value="studies">
      <StudyListing v-model:search="routeParams.searchText" v-model:filters="routeParams.extraFilters" />
    </v-window-item>
  </v-window>
</template>

<script lang="ts">
  import { Component, Prop, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams, getRingTypesByKey } from '@jouzen/outo-toolkit-vuetify'

  import { appFlavors, fileHeaders, protoHeaders, studyHeaders } from '#views/rdata/constants'

  @Component
  class ResearchView extends mixins(RouteParams) {
    @Prop() public tab!: string

    public routeParams: any = {
      searchText: '',
      extraFilters: [],
      includeArchived: 'false',
    }

    public fileDialog = false
    public studyDialog = false
    public protoDialog = false

    public readonly appFlavors = appFlavors

    public readonly fileHeaders = fileHeaders
    public readonly studyHeaders = studyHeaders
    public readonly protoHeaders = protoHeaders

    public readonly hardwareTypes = getRingTypesByKey('researchValue')
  }

  export default toNative(ResearchView)
</script>

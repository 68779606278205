<template>
  <v-dialog
    v-if="open && rollout"
    :width="800"
    :persistent="!!action"
    :model-value="open"
    @update:model-value="close()"
  >
    <v-card>
      <v-card-title>
        {{ rolloutTitle }}

        <v-spacer />

        <span v-if="rollout.state" class="state font-weight-light" :class="rollout.state">
          {{ rollout.state.toUpperCase() }}
        </span>
      </v-card-title>

      <v-card-text>
        <div class="text-overline px-3">Filters</div>

        <v-row class="px-4">
          <v-col cols="12" class="px-4">
            <div v-if="!rollout.filters.length">No filters were used in this rollout</div>

            <v-list v-for="filter in rollout.filters" :key="filter" class="my-n2 mx-n4">
              <v-list-item
                :title="filter"
                :subtitle="
                  filter.startsWith('label:') && rollout.info?.list
                    ? sampleFilters.find((s) => s.uid === rollout.info?.list)?.name
                      ? sampleFilters.find((s) => s.uid === rollout.info?.list)?.name +
                        ' sample list was used to create the label'
                      : 'Existing user label was used'
                    : ''
                "
              >
                <template #append>
                  <v-tooltip text="Copy label name" location="start">
                    <template #activator="{ props }">
                      <v-btn
                        v-if="filter.startsWith('label:')"
                        v-bind="props"
                        icon="mdi-content-copy"
                        @click="clipboard.copy(filter.slice(6))"
                      />
                    </template>
                  </v-tooltip>
                </template>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>

        <div v-if="rollout.info.actor" class="text-overline px-3 pt-3">Creator</div>

        <v-row v-if="rollout.info.actor" class="px-4">
          <v-col cols="12" class="px-4">
            <div>{{ rollout.info.actor }}</div>
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-3">Filename</div>

        <v-row class="px-4">
          <v-col cols="12" class="px-4">
            <div>{{ rollout.value.type }}-{{ rollout.value.version }}-{{ rollout.value.slug }}.zip</div>
          </v-col>
        </v-row>

        <div class="text-overline px-3 pt-3">Schedule</div>

        <v-row>
          <v-col cols="12">
            <v-data-table hide-default-footer :headers="headers" :items="rollout.schedule" :items-per-page="100">
              <template #item.apply_at="{ item }">
                <span>{{ new Date(item.apply_at).toUTCString() }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <div v-if="rollout.platform" class="text-overline px-3 py-3">Platform</div>

        <v-row v-if="rollout.platform" class="px-4">
          <v-col cols="6">
            <div>Platform:</div>
            <div>Min version:</div>
            <div>Max version:</div>
          </v-col>

          <v-col cols="6" class="px-4">
            <div>{{ rollout.platform }}</div>
            <div>{{ rollout.min_version || '-' }}</div>
            <div>{{ rollout.max_version || '-' }}</div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn v-if="!action" text="Close" @click="close()" />

        <template v-else>
          <v-btn text="Cancel" class="mr-2" @click="close()" />

          <v-btn text="Confirm" color="primary" :disabled="isLoading" @click="confirm()" />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { UseClipboardReturn, useClipboard } from '@vueuse/core'

  import { Component, Emit, Prop, Setup, Vue, toNative } from 'vue-facing-decorator'

  import { getRingTypeByValue } from '@jouzen/outo-toolkit-vuetify'

  import { appFlavors, detailHeaders } from '#views/ota/constants'

  import { OtaStore, SetupStore } from '#stores'

  import { Rollout } from '#types'

  @Component
  class PreviewRollout extends Vue {
    @Prop() public open!: boolean

    @Prop() public action!: any
    @Prop() public rollout!: Rollout

    public readonly headers = detailHeaders

    @Setup(() => useClipboard())
    public readonly clipboard!: UseClipboardReturn<false>

    private readonly otaStore = new OtaStore()
    private readonly setupStore = new SetupStore()

    @Emit('close')
    public close() {
      return null
    }

    @Emit('confirm')
    public confirm() {
      return {
        action: this.action,
        rollout: this.rollout,
      }
    }

    public get isLoading() {
      return this.otaStore.loading
    }

    public get rolloutTitle() {
      return [
        this.action?.title,
        getRingTypeByValue(this.rollout.value.type.split('_')[1])?.title,
        appFlavors.find((t) => t.value === this.rollout.flavor)?.title,
        this.rollout.value.version,
        this.action ? 'Rollout?' : undefined,
      ]
        .filter(Boolean)
        .join(' ')
    }

    public get sampleFilters() {
      return this.setupStore.sampleFilters.filter((s) => s.type === 'user_uuid')
    }
  }

  export default toNative(PreviewRollout)
</script>

<style lang="scss" scoped>
  .state {
    &.active,
    &.verified {
      color: green;
    }

    &.pending,
    &.disabled {
      color: orange;
    }

    &.invalid {
      color: red;
    }
  }
</style>

<template>
  <v-app-bar v-if="active" title="Dashboard">
    <v-spacer />

    <v-autocomplete
      v-model="selectedDashboard"
      class="text-primary"
      style="min-width: 500px; max-width: 500px"
      item-value="dashboardId"
      item-title="dashboardName"
      placeholder="Select dashboard to show..."
      :items="dashboards"
      :color="selectedDashboard ? 'primary' : ''"
    />

    <v-btn
      icon="mdi-reload"
      :loading="loading"
      :color="loading ? 'primary' : ''"
      :disabled="!selectedDashboard || !sessionEnd"
      @click="showDashboard()"
    />
  </v-app-bar>

  <v-progress-linear v-if="loading || !dashboards.length" />

  <v-alert v-if="!!errorText" type="error">{{ errorText }}</v-alert>

  <v-container>
    <v-row v-if="!url && !errorText && dashboards.length && firstSession">
      <v-col cols="12" style="padding: 100px">
        <strong class="text-primary text-h5">Important information about the dashboards usage, please read:</strong>
        <br />
        <br />

        <div>
          When you open the dashboard from the dropdown, it consumes a Quicksight dashboard usage session.
          <br />
          <br />
          We have a limited number of those sessions in a month, so please be mindful when accessing.
          <br />
          <br />
          Once opened, your session is valid for 30 minutes.
        </div>
      </v-col>
    </v-row>

    <v-expand-transition v-if="selectedDashboard">
      <div style="position: relative; width: 100%">
        <iframe :src="url" title="Dashboard" style="border: none; width: 100%; height: calc(100vh - 215px)" />
      </div>
    </v-expand-transition>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { DashboardStore } from '#stores'

  @Component
  class Dashboard extends Vue {
    @Prop() public active!: boolean

    public errorText = ''

    public sessionEnd = false
    public firstSession = true

    public sessionTimeout: any = undefined

    public selectedDashboard: string | null = null

    private readonly dashboardStore = new DashboardStore()

    public get url() {
      return this.dashboardStore.url
    }

    public get loading() {
      return this.dashboardStore.loading
    }

    public get dashboards() {
      return this.dashboardStore.dashboards
    }

    @Watch('url')
    protected urlChanged() {
      clearTimeout(this.sessionTimeout)

      this.sessionTimeout = setTimeout(
        () => {
          this.sessionEnd = true
        },
        30 * 60 * 1000,
      )
    }

    @Watch('selectedDashboard')
    protected selectedDashboardChanged() {
      if (this.selectedDashboard !== this.$route.params.id) {
        this.$router.replace({ name: 'dashboard', params: { id: this.selectedDashboard } })
      }

      if (this.selectedDashboard) {
        this.showDashboard()
      }
    }

    public async created() {
      await this.dashboardStore.listDashboards()

      if (this.$route.params.id && this.dashboards.find((d) => d.dashboardId === this.$route.params.id)) {
        this.selectedDashboard = this.$route.params.id as string
      }
    }

    public beforeUnmount() {
      clearTimeout(this.sessionTimeout)
    }

    public async showDashboard() {
      this.sessionEnd = false

      const response = await this.dashboardStore.getDashboardUrl(this.selectedDashboard ?? '')

      if (!response?.data) {
        this.errorText = 'Unknown error happened when fetching the dashboard url!'
      } else {
        this.errorText = ''

        this.firstSession = false
      }
    }
  }

  export default toNative(Dashboard)
</script>

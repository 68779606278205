<template>
  <v-app-bar>
    <v-tabs style="max-width: 750px" :model-value="tab">
      <v-tab to="lists">Sample lists</v-tab>
      <v-tab to="alerts">Schedule alerts</v-tab>
      <v-tab to="tasks">Scheduled tasks</v-tab>
    </v-tabs>

    <v-spacer />

    <v-menu location="bottom end" offset="16" min-width="200">
      <template #activator="{ props }">
        <v-chip v-bind="props" class="font-weight-bold mr-3" append-icon="mdi-chevron-down">
          {{ cloudEnvs.find((e: any) => e.value === routeParams.cloudEnv)?.title || '' }}
        </v-chip>
      </template>

      <v-list class="px-4 pb-6">
        <v-list-subheader>Configuration clients</v-list-subheader>

        <v-list-item
          v-for="(env, index) in cloudEnvs"
          :key="index"
          :title="env.title"
          @click="routeParams.cloudEnv = env.value"
        />
      </v-list>
    </v-menu>
    <v-menu v-if="tab === 'lists'" offset="8" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-filter-variant-plus" />
      </template>

      <v-list>
        <v-list-subheader>Select additional filters</v-list-subheader>

        <v-list-item>
          <v-switch
            label="Show archived lists"
            :model-value="routeParams.includeArchived === 'true'"
            @update:model-value="routeParams.includeArchived = $event ? 'true' : 'false'"
          />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container>
    <v-window v-model="tab">
      <v-window-item value="lists">
        <FiltersView :env="routeParams.cloudEnv" />
      </v-window-item>

      <v-window-item value="alerts">
        <AlertsView :env="routeParams.cloudEnv" />
      </v-window-item>

      <v-window-item value="tasks">
        <SchedulesView :env="routeParams.cloudEnv" />
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { cloudEnvs } from '#views/setup/constants'

  import { SetupStore } from '#stores'

  @Component
  class SetupView extends mixins(RouteParams) {
    @Prop() public tab!: string

    public readonly env = import.meta.env.VITE_APP_ENV

    public routeParams = {
      cloudEnv: this.env === 'release' ? 'prod' : this.env === 'staging' ? 'stage' : 'test',
      includeArchived: 'false',
    }

    public readonly cloudEnvs = cloudEnvs

    private readonly setupStore = new SetupStore()

    @Watch('routeParams.includeArchived', { immediate: true })
    protected includeArchivedChanged(val: string) {
      this.setupStore.updateIncludeArchived(val === 'true')
    }
  }

  export default toNative(SetupView)
</script>

import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'

import { AppStore } from '#stores'

import { ProtoFile } from '#types'

let unsubscribe: Unsubscribe | undefined = undefined

@Store
export class ProtoStore extends Pinia {
  public loading = false

  public files: ProtoFile[] = []

  public async createFile(file: any) {
    const appStore = new AppStore()

    file.createdAt = serverTimestamp()
    file.updatedAt = serverTimestamp()

    file.createdBy = appStore.user.email
    file.updatedBy = appStore.user.email

    return await setDoc(doc(this.$firestore, `/protocols/${file.id}`), file)
  }

  public async updateFile(file: ProtoFile) {
    const appStore = new AppStore()

    file.updatedAt = serverTimestamp()

    file.updatedBy = appStore.user.email

    return await updateDoc(doc(this.$firestore, `/protocols/${file.id}`), file)
  }

  public async deleteFile(file: ProtoFile) {
    return await deleteDoc(doc(this.$firestore, `/protocols/${file.id}`))
  }

  public async subscribeToProto() {
    if (unsubscribe) {
      return
    }

    this.loading = true

    unsubscribe = onSnapshot(query(collection(this.$firestore, '/protocols')), (snap) => {
      const files = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      this.files = sortBy(files, [(o: any) => o.createdAt || '']).reverse()

      this.loading = false
    })
  }

  public async unsubscribeFromProto() {
    if (unsubscribe) {
      unsubscribe()

      unsubscribe = undefined
    }
  }
}

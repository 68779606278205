<template>
  <div>
    <v-text-field
      clearable
      class="mr-2"
      style="width: 100vw; max-width: 500px"
      prepend-icon="mdi-magnify"
      placeholder="Type to filter (task id, hw or fw separted by commas)..."
      :model-value="searchText"
      @update:model-value="searchText = $event"
      @click:clear="searchText = ''"
    />
  </div>
</template>

<script lang="ts">
  import { Component, Model, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { TasksStore } from '#stores'

  @Component
  class TaskSearch extends Vue {
    @Model({ name: 'search' }) public searchText!: string

    private readonly tasksStore = new TasksStore()

    @Watch('searchText')
    protected searchTextChanged() {
      this.updateResults()
    }

    @Debounce(100)
    public updateResults() {
      this.tasksStore.filterTasks({
        search: this.searchText,
      })
    }
  }

  export default toNative(TaskSearch)
</script>

<template>
  <v-card>
    <v-card-title>JSON preview {{ hasChanges ? 'changes' : '' }}</v-card-title>

    <v-card-text>
      <div class="mt-n4" style="position: relative">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="edited" class="diff-container" style="position: relative" v-html="htmlDiff" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
  import sortKeys from 'sort-keys'

  import * as Diff2Html from 'diff2html'
  import { ColorSchemeType } from 'diff2html/lib/types'

  import { isEqual } from 'lodash-es'

  import { createTwoFilesPatch } from 'diff'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Prop, Setup, Vue, toNative } from 'vue-facing-decorator'

  @Component
  class JsonPreview extends Vue {
    @Prop() public edited!: any
    @Prop() public original!: any

    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    public get htmlDiff() {
      const diff = isEqual(this.edited, this.original)
        ? this.getDiff(null, this.edited)
        : this.getDiff(this.original, this.edited)

      const colorScheme = this.theme?.global?.current?.value?.dark ? ColorSchemeType.DARK : ColorSchemeType.LIGHT

      return Diff2Html.html(diff ?? '', { drawFileList: false, colorScheme: colorScheme })
    }

    public get hasChanges() {
      return !isEqual(this.edited, this.original)
    }

    private getDiff(before: any, after: any) {
      // Get rid of setters & getters because sortKeys breaks otherwise
      after = typeof after == 'object' ? JSON.parse(JSON.stringify(after)) : after
      before = typeof before == 'object' ? JSON.parse(JSON.stringify(before)) : before

      const name = (before ? before.timestamp : '') + (before && after ? '->' : '') + (after ? after.timestamp : '')

      const stringify = (data: any) => (data ? JSON.stringify(sortKeys(data, { deep: true }), null, '  ') : '')

      const diff = createTwoFilesPatch(name, name, stringify(before), stringify(after))

      if (diff) {
        return `diff --git a/${name} b/${name}\n${diff}`
      }

      return null
    }
  }

  export default toNative(JsonPreview)
</script>

<style lang="scss" scoped>
  :deep(.d2h-info),
  :deep(.d2h-file-header) {
    display: none;
  }
</style>

<template>
  <v-row>
    <div class="text-overline px-3">Firmware versions</div>

    <v-col cols="12">
      <v-table>
        <template #default>
          <thead>
            <tr>
              <th
                v-for="header in rolloutHeaders"
                :key="header.key"
                scope="col"
                :class="header.align === 'end' ? 'text-right' : ''"
              >
                {{ header.title }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in configuredStudyRollouts" :key="item.id">
              <td>
                {{
                  item.value.type
                    .split('_')[1]
                    .replace('gen2x', 'Gen3')
                    .replace(/^oreo$/, 'Oreo')
                }}
              </td>

              <td>{{ item.value.version }}</td>

              <td class="state" :class="item.state">
                {{ item.state.toUpperCase() }}
              </td>

              <td>
                {{ new Date(item.created_at).toUTCString() }}
              </td>

              <td class="text-right pr-0">
                <v-btn
                  color="info"
                  variant="text"
                  :disabled="isLoading || !hasRolloutRights"
                  @click="showRolloutDialog({ title: 'Edit', value: 'archived' }, item)"
                >
                  Edit
                </v-btn>

                <v-btn
                  v-if="item.state === 'pending' || item.state === 'disabled'"
                  variant="text"
                  color="success"
                  :disabled="isLoading || !hasRolloutRights"
                  @click="showPreviewDialog({ title: 'Activate', value: 'active' }, item)"
                >
                  Enable
                </v-btn>

                <v-btn
                  v-else-if="item.state !== 'disabled'"
                  variant="text"
                  color="error"
                  :disabled="isLoading || !hasRolloutRights"
                  @click="showPreviewDialog({ title: 'Disable', value: 'disabled' }, item)"
                >
                  Disable
                </v-btn>
              </td>
            </tr>

            <tr v-for="item in configurableFirmwareRollouts" :key="item.value">
              <td>{{ item.title }}</td>
              <td class="text-left">
                {{ config.appFlavor === 'research' ? 'Flashed manually' : 'Public OTA rollouts' }}
              </td>
              <td>INACTIVE</td>
              <td>Not setup yet</td>
              <td class="text-right pr-0">
                <v-btn
                  color="primary"
                  :disabled="isLoading || !hasRolloutRights"
                  @click="setupRollout({ title: 'Setup', value: 'setup' }, item)"
                >
                  Setup rollout
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </v-col>

    <v-alert
      density="compact"
      type="info"
      text="HINT: You can rollout version that is manually flashed to block any other OTA updates"
    />
  </v-row>

  <SetupRollout
    v-model="rolloutDialog"
    :team="config"
    :hw-type="hwType"
    :action="action"
    :rollout="rollout"
    @confirm="storeRollouts($event)"
  />

  <PreviewRollout
    :open="previewDialog"
    :action="action"
    :rollout="rollout"
    @close="closePreviewDialog()"
    @confirm="changeRolloutState()"
  />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { hwTypesForRollouts, rolloutHeaders } from '#views/rdata/constants'

  import { AppStore, OtaStore, RdataStore, TeamsStore } from '#stores'

  import { Rollout, Study } from '#types'

  @Component
  class RolloutsEdit extends Vue {
    @Prop() public config!: Study

    @Prop() public disabled!: boolean

    public hwType = ''

    public previewDialog = false
    public rolloutDialog = false

    public rollout: Rollout | null = null

    public action: { title: string; value: string } = { title: '', value: '' }

    public readonly rolloutHeaders = rolloutHeaders
    public readonly hwTypesForRollouts = hwTypesForRollouts

    protected readonly appStore = new AppStore()
    protected readonly otaStore = new OtaStore()

    protected readonly rdataStore = new RdataStore()
    protected readonly teamsStore = new TeamsStore()

    public get isLoading() {
      return this.otaStore.loading || this.rdataStore.loading || this.teamsStore.loading
    }

    public get hasRolloutRights() {
      return !this.disabled && this.appStore.isOtaRolloutsAdmin
    }

    public get configuredStudyRollouts() {
      const studyRolloutsFromFilero: Rollout[] = []

      const hwTypes = this.hwTypesForRollouts.map(
        (hardwareType: { title: string; value: string }) => hardwareType.value,
      )

      const allRollouts: { [key: string]: Rollout[] } = this.otaStore.rollouts

      const studyRollouts: { [key: string]: string[] } = this.config?.rollouts ?? {}

      if (allRollouts) {
        for (const hwType of hwTypes) {
          if (studyRollouts?.[hwType] && studyRollouts[hwType].length > 0) {
            for (const rolloutSlug of studyRollouts[hwType]) {
              const foundRollout = allRollouts[hwType]?.find((r: Rollout) => r.slug === rolloutSlug)

              if (foundRollout) {
                studyRolloutsFromFilero.push(foundRollout)
              }
            }
          }
        }
      }

      return studyRolloutsFromFilero
    }

    public get configurableFirmwareRollouts() {
      return this.configuredStudyRollouts.length > 0
        ? this.hwTypesForRollouts.filter((hardwareType: { title: string; value: string }) =>
            this.configuredStudyRollouts.every((r: Rollout) => r.value.type.split('_')[1] !== hardwareType.value),
          )
        : this.hwTypesForRollouts
    }

    public async mounted() {
      await this.otaStore.listRollouts()
    }

    public setupRollout(action: { title: string; value: string }, item: any) {
      this.rollout = null

      this.action = action

      this.hwType = item.value || item.value.type.split('_')[1]

      this.rolloutDialog = true
    }

    public showRolloutDialog(action: { title: string; value: string }, rollout: Rollout) {
      this.action = action
      this.rollout = rollout

      this.hwType = rollout.value.type.split('_')[1]

      this.rolloutDialog = true
    }

    public showPreviewDialog(action: { title: string; value: string }, rollout: Rollout) {
      this.action = action
      this.rollout = rollout

      this.previewDialog = true
    }

    public closePreviewDialog() {
      this.action = { title: '', value: '' }
      this.rollout = null

      this.previewDialog = false
    }

    public async storeRollouts(rollout: Rollout) {
      if (this.config) {
        const rollouts = this.config.rollouts || {}

        const hwType = rollout.value.type.split('_')[1]

        rollouts[hwType] = rollouts[hwType] || []

        rollouts[hwType].push(rollout.slug)

        await this.teamsStore.saveRollouts({ id: this.config.id, rollouts })
      }
    }

    public async changeRolloutState() {
      await this.otaStore.changeState({
        state: this.action.value,
        rollout: this.rollout,
      })

      this.closePreviewDialog()
    }
  }

  export default toNative(RolloutsEdit)
</script>

<style lang="scss" scoped>
  .state {
    &.active {
      color: green;
    }

    &.pending {
      color: orange;
    }

    &.disabled {
      color: black;
    }
  }
</style>

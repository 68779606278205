import { sortBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import {
  Unsubscribe,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { getBlob, listAll, ref } from 'firebase/storage'

import { AppStore } from '#stores'

import { RdataFile } from '#types'

let unsubscribe: Unsubscribe | undefined = undefined

@Store
export class RdataStore extends Pinia {
  public loading = false

  public files: any[] = []
  public schemas: any[] = []

  public async createFile(file: any) {
    const appStore = new AppStore()

    file.createdAt = serverTimestamp()
    file.updatedAt = serverTimestamp()

    file.createdBy = appStore.user.email
    file.updatedBy = appStore.user.email

    return await setDoc(doc(this.$firestore, `/rdata/${file.id}`), file)
  }

  public async updateFile(file: any) {
    const appStore = new AppStore()

    file.updatedAt = serverTimestamp()

    file.updatedBy = appStore.user.email

    return await updateDoc(doc(this.$firestore, `/rdata/${file.id}`), file)
  }

  public async deleteFile(file: any) {
    return await deleteDoc(doc(this.$firestore, `/rdata/${file.id}`))
  }

  public async updateLiveSync(file: RdataFile | null) {
    const appStore = new AppStore()

    if (appStore.user?.id) {
      await setDoc(
        doc(this.$firestore, `/users/${appStore.user.id}`),
        {
          rdata: file?.id ?? null,
        },
        { merge: true },
      )
    }

    if (appStore.user?.id && file?.id) {
      await setDoc(doc(this.$firestore, `/users/${appStore.user.id}/rdata/${file.id}`), {
        id: file.id,
        name: file.name,
        data: file.data,
        createdAt: file.createdAt,
        createdBy: file.createdBy,
        updatedAt: file.updatedAt,
        updatedBy: file.updatedBy,
        modifiedAt: serverTimestamp(),
      })
    }
  }

  public async subscribeToRdata() {
    if (unsubscribe) {
      return
    }

    const schemas: any[] = []

    this.loading = true

    const rdata = await listAll(ref(this.$fbstorage, 'rdata'))

    for (const item of rdata.items) {
      const schema = await getBlob(item)

      schemas.push({ ...JSON.parse(await schema.text()), name: item.name })
    }

    this.schemas = schemas

    unsubscribe = onSnapshot(query(collection(this.$firestore, '/rdata')), (snap) => {
      const files = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      this.files = sortBy(files, [(o: any) => o.createdAt || '']).reverse()

      this.loading = false
    })
  }

  public async unsubscribeFromRdata() {
    if (unsubscribe) {
      unsubscribe()

      unsubscribe = undefined
    }
  }

  public async searchStudyUsersByEmail(email: string) {
    const payload = { emails: [email] }

    const response = await this.$axios.post(`/admin/user-email-search`, payload)

    return response?.data
  }
}

import { PrefsStore } from '#stores'

import { TaskSettings } from '#types'

export function createDefaultTask(): TaskSettings {
  const prefsStore = new PrefsStore()

  return {
    info: {
      name: '',
      description: '',
    },
    parameters: {
      maxUsers: '2500',

      fwVersions: '',
      deviceModels: [],

      hwType: 'gen2x',
      osSplit: 'ios:50',

      blacklistedLabels: 'outo_custom:poirot_exclude',
      analyzeSampleMode: 'random',

      inputOptions: '',
      inputCommand: prefsStore.runCommand,
      inputCommandBranch: prefsStore.tlogBranch,
    },
  }
}

export const statuses = [
  {
    field: 'Fail',
    value: 'FAILED',
  },
  {
    field: 'Pass',
    value: 'SUCCEEDED',
  },
]

export const jzlogSources = [
  {
    env: 'test',
    title: 'Debug app',
    value: 'debug',
  },
  {
    env: 'stage',
    title: 'Staging app',
    value: 'staging',
  },
  {
    env: 'prod',
    title: 'Release app',
    value: 'release',
  },
  {
    env: 'prod',
    title: 'Research app',
    value: 'research',
  },
  {
    env: 'stage',
    title: 'Experimental app',
    value: 'experimental',
  },
  {
    title: 'Factory test runs',
    value: 'factory',
  },
]

export const osSelections = [
  {
    title: 'Equally all',
    value: 'ios:50',
  },
  {
    title: 'iOS',
    value: 'ios:100',
  },
  {
    title: 'Android',
    value: 'ios:0',
  },
]

export const filterTasks = [
  {
    title: 'Show only my tasks',
    value: 'email',
  },
  {
    title: 'Show only named tasks',
    value: 'name',
  },
  {
    title: 'Show only scheduled tasks',
    value: 'schedule',
  },
  {
    title: 'Filter tasks by default binary',
    value: 'binary',
  },
  {
    title: 'Filter tasks by selected sample type',
    value: 'sample',
  },
]

export const osSummaryHeaders: any = [
  {
    key: 'data-table-expand',
  },
  {
    key: 'name',
    title: 'Event',
  },
  {
    key: 'count',
    title: 'Amount of events',
    align: 'center',
  },
  {
    key: 'distinct_ring_count',
    title: 'Distinct rings count',
    align: 'center',
  },
  {
    key: 'count_per_ring',
    title: 'Events per rings %',
    align: 'center',
  },
  {
    key: 'count_per_night',
    title: 'Events per night %',
    align: 'center',
  },
  {
    key: 'count_per_distinct_ring',
    title: 'Events in distinct rings',
    align: 'center',
  },
]

export const topOccurrenceHeaders: any = [
  {
    key: 'uid',
    title: 'UID with top occurrences of this event',
  },
  {
    key: 'occurrence_count',
    title: 'Occurrence count',
    align: 'center',
  },
]

export const dumpSummaryHeaders: any = [
  {
    key: 'filename',
    title: 'Filename',
  },
  {
    key: 'error',
    title: 'Error',
  },
  {
    key: 'failed_in',
    title: 'Failed in',
  },
  {
    key: 'link_register',
    title: 'Link register',
  },
  {
    key: 'PC',
    title: 'PC',
  },
  {
    key: 'PSR',
    title: 'PSR',
  },
  {
    key: 'CFSR',
    title: 'CFSR',
  },
]

export const tempSummaryHeaders: any = [
  {
    key: 'source',
    title: 'Source',
  },
  {
    key: 'abs_min',
    title: 'Absolute Min',
    align: 'center',
  },
  {
    key: 'abs_max',
    title: 'Absolute Max',
    align: 'center',
  },
  {
    key: 'avg_min',
    title: 'Average Min',
    align: 'center',
  },
  {
    key: 'avg_max',
    title: 'Average Max',
    align: 'center',
  },
  {
    key: 'popul_avg',
    title: 'Popul Average',
    align: 'center',
  },
  {
    key: 'count',
    title: 'Count',
    align: 'center',
  },
]

export const batterySlopeHeaders: any = [
  {
    key: 'status',
    title: 'Status',
    cellClass: 'text-uppercase',
  },
  {
    key: 'count',
    title: 'Count',
    align: 'center',
  },
  {
    key: 'average',
    title: 'Average %',
    align: 'center',
  },
  {
    key: 'sum',
    title: 'Sum',
    align: 'center',
  },
]

export const batterySlopeWorstCaseHeaders: any = [
  {
    title: 'UID',
    key: 'uid',
    sortable: false,
  },
  {
    key: 'average',
    title: 'Average %',
    align: 'center',
  },
]

export const taskStarterTabs = [
  {
    title: 'Random samples',
    value: 'random',
  },
  {
    title: 'Select samples',
    value: 'select',
  },
  {
    title: 'Upload samples',
    value: 'upload',
  },
]

export const jsonSample = {
  users: [
    'e57862f6-a8a5-4930-ba14-1ef5924da75c:2.50.4:gen2:android',
    'e67f5956-ef43-4979-95dc-07cf8aa15041:2.50.4:gen2:android',
    'fbb5539a-0209-47cf-a980-b91f448ddd35:2.50.4:gen2:android',
    '9fcd995a-6d8d-453c-b4fd-aa77b982a90d:2.50.4:gen2:ios',
    'def350cb-6693-4375-aacf-f8b359a2caa5:2.50.4:gen2:ios',
  ],
}

export const testPhases = [
  {
    title: 'Premold',
    value: 'premold',
  },
  {
    title: 'Panel',
    value: 'panel',
  },
  {
    title: 'Final',
    value: 'final',
  },
  {
    title: 'PCB',
    value: 'pcb',
  },
  {
    title: 'Final retest',
    value: 'final-retest',
  },
  {
    title: 'Premold retest',
    value: 'premold-retest',
  },
  {
    title: 'Factory reset final phase',
    value: 'factory_reset_final_phase',
  },
  {
    title: 'Factory reset premold phase',
    value: 'factory_reset_premold_phase',
  },
  {
    title: 'Factory reset',
    value: 'factory_reset',
  },
  {
    title: 'Set final flag',
    value: 'set_final_flag',
  },
  {
    title: 'Set panel flag',
    value: 'set_panel_flag',
  },
]

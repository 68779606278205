<template>
  <v-card>
    <v-toolbar :color="hasSelectedProtocols ? 'primary' : 'secondary'">
      <v-toolbar-title v-if="!hasSelectedProtocols">Protocol files</v-toolbar-title>
      <v-toolbar-title v-else class="text-white">{{ selectedProtocols.length }} files selected</v-toolbar-title>

      <v-spacer />

      <v-toolbar-items v-if="hasSelectedProtocols" color="white">
        <v-btn color="white" :disabled="disabled" @click="removeProtocols()">
          Remove selected

          <template #append>
            <v-icon>mdi-delete</v-icon>
          </template>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn :disabled="disabled" @click="((selectedProtocols = []), (selectDialog = true))">
          Add protocol file

          <template #append>
            <v-icon>mdi-plus-thick</v-icon>
          </template>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-data-table
      sort-desc
      :loading="isLoading"
      :headers="protocolHeaders"
      :items="studyProtos"
      :items-per-page="20"
      :items-per-page-options="[
        { title: '20', value: 20 },
        { title: '100', value: 100 },
        { title: 'All', value: -1 },
      ]"
    >
      <template #header.actions>
        <v-checkbox
          :disabled="disabled || !config.protocols?.length"
          :model-value="hasSelectedProtocols && selectedProtocols.length === config.protocols?.length"
          @update:model-value="
            $event ? (selectedProtocols = config.protocols!.map((p: any) => p.id)) : (selectedProtocols = [])
          "
        />
      </template>

      <template #item.actions="{ item }">
        <v-checkbox
          :disabled="disabled"
          :model-value="!!selectedProtocols.find((s) => s === item.id)"
          @click.stop
          @update:model-value="
            $event
              ? selectedProtocols.push(item.id)
              : (selectedProtocols = selectedProtocols.filter((s) => s !== item.id))
          "
        />
      </template>

      <template #item.name="{ item }">
        {{ item.name }}
        <v-btn icon="mdi-open-in-new" link :to="`/rdata/protocols/${item.id}`" target="_blank" rounded />
      </template>

      <template #item.count="{ item }">
        {{ (protoFiles.find((p) => p.id === item.id) || {})?.activities?.length || '0' }}
      </template>

      <template #item.addedAt="{ item }">
        {{ $dayjs(item.addedAt).format('HH:mm:ss on DD MMM YYYY') }}
      </template>

      <template #item.data-table-expand>
        <!-- Hide expand icons for all rows  -->
        <v-icon />
      </template>
    </v-data-table>
  </v-card>

  <SelectProtos :team="config" :open="selectDialog" @cancel="selectDialog = false" @confirm="addProtocols($event)" />
</template>

<script lang="ts">
  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { protocolHeaders } from '#views/rdata/constants'

  import { AppStore, ProtoStore, RdataStore, TeamsStore } from '#stores'

  import { Study, StudyProtocol } from '#types'

  @Component
  class ManageProtos extends Vue {
    @Prop() public config!: Study

    @Prop() public disabled!: boolean

    public selectDialog = false

    public selectedProtocols: string[] = []

    public readonly protocolHeaders = protocolHeaders

    protected readonly appStore = new AppStore()

    protected readonly rdataStore = new RdataStore()
    protected readonly teamsStore = new TeamsStore()
    protected readonly protoStore = new ProtoStore()

    public get isLoading() {
      return this.rdataStore.loading || this.teamsStore.loading || this.protoStore.loading
    }

    public get protoFiles() {
      return this.protoStore.files
    }

    public get studyProtos() {
      return this.protoFiles.flatMap((protoFile) => {
        const editedProto = this.config.protocols?.find((editedProto) => editedProto.id === protoFile.id)
        if (editedProto) {
          // Add to list
          return [{ ...protoFile, addedAt: editedProto.addedAt }]
        } else {
          // Dont add to list
          return []
        }
      })
    }

    public get hasSelectedProtocols() {
      return this.selectedProtocols.length > 0
    }

    public addProtocols(protocolIds: string[]) {
      if (this.config) {
        const newProtocols = protocolIds.map((id: string) => ({ id, addedAt: Date.now() }))

        this.config.protocols = [...(this.config.protocols || []), ...newProtocols]
      }

      this.selectDialog = false
    }

    public removeProtocols() {
      if (this.config.protocols) {
        const selectedIds = new Set(this.selectedProtocols)

        this.config.protocols = this.config.protocols.filter((p: StudyProtocol) => !selectedIds.has(p.id))
      }

      this.selectedProtocols = []
    }
  }

  export default toNative(ManageProtos)
</script>

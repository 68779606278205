<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-container>
    <v-row>
      <v-col cols="9">
        <div class="text-h5 font-weight-light">Annotation protocols for research data collections</div>

        <div class="text-subtitle-2 text-grey-darken-2 font-weight-light">
          <template v-if="isResearchAdmin">
            Your research data admin rights allow you to view and edit all protocols
          </template>
          <template v-else>You can view all protocols, but can not edit or create them</template>
        </div>
      </v-col>

      <v-col cols="3" class="d-flex justify-end align-top">
        <v-btn text="Create new protocol" color="primary" :disabled="!isResearchAdmin" @click="protoDialog = true" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            :search="search"
            :loading="loading"
            :headers="protoHeaders"
            :items="protoFiles"
            :items-per-page="100"
            :items-per-page-options="[
              { title: '100', value: 100 },
              { title: '500', value: 500 },
              { title: '1000', value: 1000 },
            ]"
            :custom-filter="filterProtocolFiles"
            @click:row="(_event: any, row: any) => openEditor(row.item)"
          >
            <template #item.name="{ item }">
              <span>{{ item.name || '(File has no name)' }}</span>
            </template>

            <template #item.activities="{ item }">
              <span>
                {{ item.activities.length }}
              </span>
            </template>

            <template #item.createdAt="{ item }">
              <span>
                {{ item.createdAt ? $dayjs(item.createdAt.toDate()).format('DD MMM YYYY') : '' }}
              </span>
            </template>

            <template #item.updatedAt="{ item }">
              <span>
                {{ item.updatedAt ? $dayjs(item.updatedAt.toDate()).format('HH:mm - DD MMM YYYY') : '' }}
              </span>
            </template>

            <template #item.actions="{ item }">
              <v-menu left offset-y>
                <template #activator="{ props }">
                  <v-btn v-bind="props" class="mx-n2" icon="mdi-dots-vertical" @click.stop.prevent />
                </template>

                <v-list dense>
                  <v-list-item
                    class="text-error"
                    title="Delete file"
                    prepend-icon="mdi-delete"
                    @click="deleteProtocolFile(item)"
                  />
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <ProtoCreate v-model="protoDialog" @create="createProtocolFile($event)" />
</template>

<script lang="ts">
  import { snakeCase } from 'lodash-es'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { appFlavors, fileHeaders, protoHeaders, studyHeaders } from '#views/rdata/constants'

  import { AppStore, ProtoStore } from '#stores'

  @Component
  class RdataView extends Vue {
    @Prop() public search!: string

    public fileDialog = false
    public studyDialog = false
    public protoDialog = false

    public readonly appFlavors = appFlavors

    public readonly fileHeaders = fileHeaders
    public readonly studyHeaders = studyHeaders
    public readonly protoHeaders = protoHeaders

    private readonly appStore = new AppStore()
    private readonly protoStore = new ProtoStore()

    public get loading() {
      return this.protoStore.loading
    }

    public get protoFiles() {
      return this.protoStore.files
    }

    public get isResearchAdmin() {
      return this.appStore.isResearchDataAdmin
    }

    public openEditor(row: any) {
      this.$router.push({ path: `/rdata/protocols/${row.id}` }).catch(() => {})
    }

    public async createProtocolFile(config: any) {
      this.protoDialog = false

      const id = snakeCase(config.name)

      await this.protoStore.createFile({
        id,
        name: config.name,
        flags: [
          {
            id: 'flag',
            name: 'Flag',
          },
        ],
        activities: [],
      })

      this.$router.push({ path: `/rdata/protocols/${id}` }).catch(() => {})
    }

    public deleteProtocolFile(proto: any) {
      this.$confirm('Confirm protocol delete?', 'Are you sure you want to delete this protocol?', {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.protoStore.deleteFile(proto)
        }
      })
    }

    public filterProtocolFiles(_value: any, search: string, item: any) {
      return (
        !!item.raw.managerEmails?.includes(search.toLowerCase()) ||
        !!item.raw.name?.toLowerCase().includes(search.toLowerCase()) ||
        !!item.raw.createdBy?.toLowerCase().includes(search.toLowerCase()) ||
        !!item.raw.activities?.find((a: any) => a.name.includes(search.toLowerCase()))
      )
    }
  }

  export default toNative(RdataView)
</script>

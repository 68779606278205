<template>
  <v-row class="mt-4">
    <v-col cols="12" md="2">
      <v-text-field
        v-model.trim="file.serialNo"
        persistent-placeholder
        clearable
        label="Serial No"
        placeholder="Enter full serial No"
        min-length="10"
        max-length="16"
        :return-object="false"
        :error="!serialIsValid"
        :error-messages="errorMessages"
        @keydown.enter="updateSearch()"
        @click:clear="file.serialNo = ''"
      />
    </v-col>

    <template v-if="tab !== 'file'">
      <v-col cols="12" md="2">
        <v-select v-model="file.factory" label="Factory" :items="factories" @keydown.enter="updateSearch()" />
      </v-col>

      <v-col cols="12" md="2">
        <v-menu
          v-model="earliestDateMenu"
          offset="8"
          min-width="auto"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ props }">
            <v-text-field
              v-bind="props"
              v-model="file.timeStart"
              clearable
              label="Earliest Test Date"
              @keydown.enter="updateSearch()"
              @click:clear="file.timeStart = ''"
            />
          </template>

          <v-date-picker
            :model-value="file.timeStart ? $dayjs(file.timeStart).toDate() : undefined"
            @update:model-value="((file.timeStart = $dayjs($event).format('YYYY-MM-DD')), (earliestDateMenu = false))"
          />
        </v-menu>
      </v-col>

      <v-col cols="12" md="2">
        <v-menu
          v-model="latestDateMenu"
          offset="8"
          min-width="auto"
          transition="scale-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ props }">
            <v-text-field
              v-bind="props"
              v-model="file.timeEnd"
              clearable
              label="Latest Test Date"
              @keydown.enter="updateSearch()"
              @click:clear="file.timeEnd = ''"
            />
          </template>

          <v-date-picker
            :model-value="file.timeEnd ? $dayjs(file.timeEnd).toDate() : undefined"
            @update:model-value="((file.timeEnd = $dayjs($event).format('YYYY-MM-DD')), (latestDateMenu = false))"
          />
        </v-menu>
      </v-col>

      <v-col cols="12" md="2">
        <v-select v-model="file.overallTestResult" label="Status" :items="statuses" @keydown.enter="updateSearch()" />
      </v-col>
      <v-col cols="12" md="2">
        <v-select v-model="file.testType" label="Test type" :items="types" @keydown.enter="updateSearch()" />
      </v-col>
    </template>
  </v-row>

  <v-expand-transition v-if="tab !== 'file'">
    <v-row v-show="extra">
      <v-col>
        <v-select
          v-model="file.productType"
          persistent-placeholder
          label="Product types"
          placeholder="Product type"
          :items="
            (file.factory === 'leab' ? leabProductTypes : sanminaProductTypes).filter(
              (t) => !t.value || t.value.includes(tab),
            )
          "
          @keydown.enter="updateSearch()"
        />
      </v-col>

      <template v-if="tab === 'ring'">
        <v-col>
          <v-select v-model="file.purpose" label="Purpose" :items="purposes" @keydown.enter="updateSearch()" />
        </v-col>

        <v-col>
          <v-select v-model="file.ringSize" label="Ring size" :items="sizes" @keydown.enter="updateSearch()" />
        </v-col>

        <v-col>
          <v-select v-model="file.ringColor" label="Ring color" :items="colors" @update:model-value="updateSearch()" />
        </v-col>

        <v-col>
          <v-select v-model="file.ringModel" label="Ring model" :items="models" @keydown.enter="updateSearch()" />
        </v-col>
      </template>

      <v-col>
        <v-text-field
          v-model.trim="file.failedMeasNo"
          persistent-placeholder
          label="Failed Measurement No"
          placeholder="e.g. 090.001"
          :rules="failedMeasNoRules"
          @keydown.enter="updateSearch()"
        />
      </v-col>
    </v-row>
  </v-expand-transition>
</template>

<script lang="ts">
  import { identity, isEmpty, omitBy, pick, pickBy } from 'lodash-es'

  import { Component, Model, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import {
    colors,
    factories,
    leabProductTypes,
    models,
    purposes,
    sanminaProductTypes,
    sizes,
    statuses,
    testTypes,
  } from '#views/tests/constants'

  import { TestsStore } from '#stores'

  @Component
  export class SearchFields extends Vue {
    @Prop() public tab!: string
    @Prop() public extra!: boolean

    @Model() public fields!: { [key: string]: string }

    public file: { [key: string]: string } = {
      productType: '',
      resultSize: '',
      serialNo: '',
      serialRegex: '',
      factory: '',
      testType: '',
      timeStart: '',
      timeEnd: '',
      purpose: '',
      ringSize: '',
      ringModel: '',
      ringColor: '',
      failedMeasNo: '',
      overallTestResult: '',
    }

    public latestDateMenu = false
    public earliestDateMenu = false

    public readonly sizes = sizes
    public readonly models = models
    public readonly colors = colors
    public readonly types = testTypes
    public readonly statuses = statuses
    public readonly purposes = purposes
    public readonly factories = factories

    public readonly leabProductTypes = leabProductTypes
    public readonly sanminaProductTypes = sanminaProductTypes

    public readonly failedMeasNoRules = [
      (v: string) => !v || (v.length === 7 && v[3] === '.') || 'Incorrect input number. Exp: 090.001',
    ]

    public errorMessages = ''
    public serialIsValid = true

    private readonly testsStore = new TestsStore()

    @Watch('tab')
    protected tabChanged() {
      this.errorMessages = ''
      this.serialIsValid = true
    }

    public mounted() {
      this.file.timeEnd = this.$dayjs().format('YYYY-MM-DD')
      this.file.timeStart = this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD')
    }

    public validateSerialLength(serialNo: string): boolean {
      return serialNo.length >= 10 && serialNo.length <= 16
    }

    public async updateSearch() {
      this.errorMessages = ''
      this.serialIsValid = true

      const serialNo = this.file.serialNo

      if (this.tab === 'file') {
        if (!serialNo || !this.validateSerialLength(serialNo)) {
          if (!serialNo) {
            this.errorMessages = 'Serial no is required'
          } else if (serialNo.length < 10) {
            this.errorMessages = 'Minimum 10 characters'
          } else {
            this.errorMessages = 'Maximum 16 characters'
          }

          this.serialIsValid = false

          return
        }

        await this.testsStore.listFactoryFileUploads(serialNo)

        this.fields = {
          serialNo: serialNo,
        }
      } else {
        if (serialNo && !this.validateSerialLength(serialNo)) {
          this.errorMessages = serialNo.length < 10 ? 'Minimum 10 characters' : 'Maximum 16 characters'

          this.serialIsValid = false

          return
        }

        await this.searchTests()
      }
    }

    public async searchTests() {
      let fields = { ...this.file }

      const timeEnd = this.file.timeEnd
      const timeStart = this.file.timeStart

      if (timeStart) {
        fields.timeRangeStart = new Date(timeStart).toISOString()
        fields.timeStart = ''
      }

      if (timeEnd) {
        fields.timeRangeEnd = new Date(timeEnd).toISOString().replace('T00:00:00.000Z', 'T23:59:59.999Z')
        fields.timeEnd = ''
      }

      if (fields.serialNo) {
        fields = pick(fields, ['serialNo'])
      } else {
        fields = pickBy(fields, identity)
        fields = omitBy(fields, (value) => !value)
      }

      await this.testsStore.searchTests({
        category: this.tab,
        fields: isEmpty(fields) ? undefined : fields,
        path: '',
      })

      this.fields = fields
    }
  }

  export default toNative(SearchFields)
</script>
